<template>
  <div>
    <div class="head" v-if="parentRoute.meta">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>
          <span @click="toPath(indexRoute, 1)" class="to_path">首页</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item
          v-if="
            parentRoute &&
            parentRoute.path != '/' &&
            parentRoute.path != '/homePage'
          "
        >
          <span @click="toPath(parentRoute, 1)" class="to_path">{{
            parentRoute.meta.title
          }}</span>
        </el-breadcrumb-item>
        <!-- <el-breadcrumb-item v-if="sonRoute">
          <span @click="toPath(sonRoute, 2)" class="to_path">{{
            sonRoute.name
          }}</span>
        </el-breadcrumb-item> -->
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail_box">
      <div class="detail_info">
        <div class="info_head">
          <div class="info_head_title">
            {{ detail.contentTitle }}
          </div>
          <div class="info_head_time">
            {{ detail.contentDatetime | timeFormat }}
          </div>
        </div>
        <div class="detail_content" v-html="detail.contentDetails"></div>
        <div
          class="detail_files"
          v-if="detail.appendixVOS && detail.appendixVOS.length > 0"
        >
          <div
            class="file_item"
            v-for="(file, i) in detail.appendixVOS"
            :key="i"
            @click="uploadFile(file)"
          >
            <img
              v-if="file.extension == 'pdf'"
              src="@/assets/image/page/pdfIcon.png"
              class="file_item_img"
            />
            <img
              v-else-if="['xls', 'xlsx'].indexOf(file.extension) >= 0"
              src="@/assets/image/page/excelIcon.png"
              class="file_item_img"
            />
            <img
              v-else
              src="@/assets/image/page/wordIcon.png"
              class="file_item_img"
            />
            <div>{{ file.name }}</div>
          </div>
        </div>
      </div>
      <div class="news_list" v-if="contanctList.length > 0">
        <div class="news_head">相关新闻</div>
        <div
          class="news_item"
          v-for="(item, i) in contanctList"
          :key="i"
          @click="toDetail(item.id)"
        >
          <div class="news_item_info">
            <div class="news_item_info_title">
              {{ item.contentTitle }}
            </div>
            <div>{{ detail.contentDatetime | timeFormat }}</div>
          </div>
          <img :src="item.contentImgUrl || $defaultImg" class="news_item_img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail } from "@/api/login";
export default {
  name: "detail",
  data() {
    return {
      id: "",
      detail: {},
      contanctList: [],
    };
  },
  computed: {
    parentRoute() {
      let routes = this.$router.options.routes;
      let obj = sessionStorage.getItem("admin_high_light_one");
      return obj ? JSON.parse(obj) : routes[0].children[0];
    },
    sonRoute() {
      let obj = sessionStorage.getItem("admin_high_light_two");
      return obj ? JSON.parse(obj) : null;
    },
    indexRoute() {
      return {
        path: "/homePage",
        meta: {
          title: "首页",
          type: "homePage",
          isAlone: true, //是否为单独的一个页面
        },
      };
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    async getDetail() {
      let res = await getDetail({
        id: this.id,
      });
      this.detail = res.data;
      let contanctList = res.data.contentList || [];
      this.contanctList = contanctList.slice(0, 5);
    },
    uploadFile(file) {
      let { url, name, extension } = file;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status === 200) {
          const blob = new Blob([this.response]);
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = name + "." + extension;
          a.click();
          window.URL.revokeObjectURL(blobUrl);
        }
      };
      xhr.send();
    },
    toPath(route, flag) {
      if (flag == 1) {
        // 记录当前的一级菜单---详情页的面包屑展示及高亮展示
        sessionStorage.setItem("admin_high_light_one", JSON.stringify(route));
        this.$router.push(route.path);
      } else {
        // 记录当前的二级菜单---详情页的面包屑展示
        sessionStorage.setItem("admin_high_light_two", JSON.stringify(route));
        this.$router.push({
          path: route.parentPath,
          query: {
            id: route.id,
          },
        });
      }
    },
    toDetail(id) {
      this.$router.push({
        path: "/detail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped>
.head {
  height: 0.53rem;
  background-color: #fefefe;
  padding-left: 0.7rem;
  display: flex;
  align-items: center;
}
.to_path {
  font-weight: 700;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #303133;
}
.to_path:hover {
  color: #409eff;
  cursor: pointer;
}
.detail_box {
  background-color: #f3f5f5;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.7rem 0.6rem 0.7rem;
}
.detail_info {
  /* width: 7.7rem; */
  flex: 1;
  background-color: #fff;
  height: 100%;
  padding: 0.2rem 0.4rem;
}
.info_head {
  padding-bottom: 0.1rem;
  border-bottom: 1px solid #eaeaea;
  color: #b5b5b6;
  font-size: 16px;
}
.info_head_title {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #3e3a39;
  font-weight: 700;
  margin-bottom: 0.1rem;
}
.detail_content {
  padding: 0.2rem 0;
  min-height: 5rem;
  border-bottom: 1px solid #eaeaea;
}
.detail_files {
  padding-top: 0.2rem;
  display: flex;
  flex-wrap: wrap;
}
.file_item {
  display: flex;
  align-items: center;
  padding: 0 0.28rem 0 0.16rem;
  height: 0.6rem;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  color: #3e3a39;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  min-width: 300px;
}
.file_item_img {
  width: 0.32rem;
  height: 0.32rem;
  margin-right: 0.1rem;
}

.news_list {
  width: 3.5rem;
  height: 5.62rem;
  background-color: #fff;
  padding: 0.2rem 0;
  margin-left: 0.2rem;
}
.news_head {
  border-left: 5px solid #d80c24;
  padding-left: 0.15rem;
  font-size: 16px;
  line-height: 16px;
  color: #3e3a39;
  margin-bottom: 0.22rem;
  font-weight: 700;
}
.news_item {
  width: 3.1rem;
  height: 0.76rem;
  margin: 0.2rem;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.news_item_info {
  height: 0.76rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #b5b5b6;
  padding: 5px 0;
}
.news_item_info_title {
  width: 1.69rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #595959;
}
.news_item_img {
  width: 1.35rem;
  height: 0.76rem;
}
</style>

<style>
.detail_content img {
  max-width: 100%;
}
</style>
